<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!-- SECTION PREVIEWER -->
        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Nos Produits</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- PRODUCTS -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row justify-center items-center">
                    <div class="col-12 col-sm-12 col-md-4">
                        <q-img width="100%" src="@/assets/media/ibc-international-bio-cosmetics-products-skin-care-cream.png" no-spinner />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                        <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Une famille de produits créée sur mesure pour répondre à vos attentes</h1>
                        <p class="text-2xl text-mineralgreen font-normal font-epigrafica mt-4">Trouvez la parfaite combinaison.</p>
                        <p class="text-2xl text-mineralgreen font-normal font-epigrafica">Trouvez le parfait équilibre epidermique pour votre peau.</p>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row justify-center">
                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <q-img class="img-smooth-zoom" width="100%" src="@/assets/media/ibc-international-bio-cosmetics-product-face.png" no-spinner />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="font-epigrafica text-mineralgreen-dark text-2xl text-justify font-bold mt-5">Produit de visage</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Hydratant</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Même si les peaux noires résistent mieux aux agressions du soleil, elles n'en demeurent pas moins sensibles. Le climat tempéré des pays occidentaux 
                                    les irrite et les assèche. Elles perdent leur éclat. Une bonne hydratation est donc primordiale pour retrouver une peau souple et douce. 
                                    Tous les soins hydratants ne valent toutefois pas. Ils doivent être adaptés à chaque type de peau. Voilà pourquoi, IBC est à pied d’œuvre 
                                    pour proposer des crèmes hydratantes répondant aux spécificités des peaux grasses, sèches ou mixtes.
                                </p>
                                <br>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Anti-imperfection</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    La peau du visage est souvent sujette à des taches pigmentaires qui peuvent avoir des origines
                                    diverses (acné, expositions au soleil, allergies …). Nous étudions spécifiquement des soins du 
                                    visage pour traiter ces imperfections de manière efficace, mais aussi d'unifier le teint.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-8 p-5">
                                <p class="font-epigrafica text-mineralgreen-dark text-2xl text-justify font-bold">Produit de corps</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Hydratant</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Une peau mal hydratée est terne et inconfortable. Elle tiraille, certaines zones sensibles 
                                    deviennent rugueuses ou pèlent... Heureusement, il suffit souvent d'un bon soin du corps 
                                    hydratant, tel que ceux issus de nos laboratoires, adapté à votre type de peau pour la réparer.
                                </p>
                                <br>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Eclaircissant / Unifiant</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Des soins qui redonnent confort et souplesse, c‘est ce qu’il faut à la peau qui reçoit des 
                                    soins unificateurs et éclaircissants. IBC en a fait son cheval de bataille en préparant des 
                                    crèmes et laits éclaircissants à base d’extraits de produits naturels, sans hydroquinone ni 
                                    corticoïde ; afin de préserver l’épiderme de la peau de ces femmes qui nous font confiance.
                                </p>
                                <br>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Gel douche</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Le gel douche a depuis longtemps détrôné le savon et se présente aujourd’hui comme le 
                                    produit le plus plébiscité pour l’hygiène corporelle quotidienne. Grâce à ses agents 
                                    tensio-actifs, notre gel douche en plus d’éliminer les impuretés sur la peau, permet 
                                    de la bichonner. Au-delà de ses propriétés lavantes, ce produit présente de multiples 
                                    qualités. Ainsi, on l’associe aujourd’hui aux notions de soin et de plaisir.
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <q-img class="img-smooth-zoom" ratio="1" src="@/assets/media/ibc-international-bio-cosmetics-product-skin.png" no-spinner />
                            </div>
                        </div>
                    </div>

                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <q-img class="img-smooth-zoom" width="100%" src="@/assets/media/ibc-international-bio-cosmetics-product-hair-1.png" no-spinner />
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <p class="font-epigrafica text-mineralgreen-dark text-2xl text-justify font-bold mt-5">Produit capillaire</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-bold">Shampooing, démêlant, Défrisant et pommade</p>
                                <p class="text-mineralgreen text-base text-justify">
                                    Les cheveux ont également besoin d’être chouchoutés comme ils le méritent, et pas n’importe comment ! 
                                    Synonymes de séduction, ils doivent être soignés avec des produits particuliers pour limiter leur 
                                    dessèchement et cassure. Le Shampoing multi nourrissant et la Crème réparatrice coiffante de IBC 
                                    en cours de production seront les alliés de la beauté des cheveux pour les discipliner et nourrir 
                                    la fibre capillaire.
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <q-img width="100%" src="@/assets/media/ibc-international-bio-cosmetics-product-hair-2.png" no-spinner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "Products",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Nos Produits', // sets document title
        })
    }
};
</script>

<style lang="scss">
</style>